import * as Yup from "yup";

export const initialValues: IPropsValues = {
  document: "",
  tradeName: "",
  legalName: "",
  email: "",
  phone: "",
  postalCode: "",
  uf: "",
  city: "",
  street: "",
  neighborhood: "",
  numberAddress: "",
  complement: "",
  unitType: undefined,
  anpCode: "",
  freightPerLiter: undefined,
  deadline: undefined,
  financialCost: undefined,
  observations: "",
};

export interface IPropsValues {
  document: string;
  tradeName: string;
  legalName: string;
  email: string;
  phone: string;
  postalCode: string;
  uf: string;
  city: string;
  street: string;
  neighborhood: string;
  numberAddress: string;
  complement: string;
  unitType?: "matriz" | "filial";
  anpCode?: string;
  freightPerLiter?: number;
  deadline?: number;
  financialCost?: number;
  observations?: string;
}

export const RegisterSchema = Yup.object().shape({
  document: Yup.string().required("CNPJ/CPF é obrigatório"),
  tradeName: Yup.string().required("Nome de Identificação é obrigatório"),
  legalName: Yup.string().required("Razão Social/Nome é obrigatório"),
  email: Yup.string().email("Email inválido").required("Email é obrigatório"),
  phone: Yup.string().required("Telefone é obrigatório"),
  postalCode: Yup.string().required("CEP é obrigatório"),
  uf: Yup.string()
    .length(2, "UF deve ter 2 caracteres")
    .required("UF é obrigatória"),
  city: Yup.string().required("Cidade é obrigatória"),
  street: Yup.string().required("Endereço é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  numberAddress: Yup.string().required("Número do endereço é obrigatório"),
  complement: Yup.string().nullable(),
  unitType: Yup.string()
    .oneOf(["matriz", "filial"])
    .required("Tipo de Unidade é obrigatório"),
  anpCode: Yup.string().required("Código ANP é obrigatório"),
  freightPerLiter: Yup.number()
    .typeError("Frete por Litro deve ser um número")
    .required("Frete por Litro é obrigatório"),
  deadline: Yup.number()
    .typeError("Prazo deve ser um número")
    .required("Prazo é obrigatório")
    .integer("Prazo deve ser um número inteiro")
    .min(0, "Prazo deve ser positivo"),
  financialCost: Yup.number()
    .typeError("Custo financeiro deve ser um número")
    .required("Custo financeiro é obrigatório")
    .min(0, "Custo financeiro deve ser positivo"),
  observations: Yup.string(),
});
