/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
  Popover,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import { formatCurrency } from "../../../utils/masks";
import CustomButton from "../../../components/Buttom";
import CustomInput from "../../../components/Input";
import { colors } from "../../../theme/colors";
import DistributorRegistrationForm from "../../../components/DistributorRegistrationForm";
import {
  initialValues,
  IPropsValues,
} from "../../../components/DistributorRegistrationForm/utils";
import { distributorsMock } from "./distributorsMock";

const DistributorsFuel = () => {
  const [data, setData] = useState<IPropsValues[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [formDistributor, setFormDistributor] =
    useState<IPropsValues>(initialValues);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsValues | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setData(distributorsMock);
  }, []);

  const handleSearch = (value: string) => {
    if (value === "") {
      setData(distributorsMock);
    } else {
      const lowerCaseValue = value.toLowerCase();
      const filtered = distributorsMock.filter((distributor) => {
        return Object.values(distributor).some((fieldValue) => {
          if (typeof fieldValue === "string") {
            return fieldValue.toLowerCase().includes(lowerCaseValue);
          }
          return false;
        });
      });
      setData(filtered);
    }
  };

  const handleAdd = () => {
    setFormDistributor(initialValues);
    setIsOpen(true);
  };

  const handleEdit = (distributor: IPropsValues) => {
    setFormDistributor(distributor);
    setIsOpen(true);
  };

  const handleDelete = (distributor: IPropsValues) => {
    const updatedData = data.filter(
      (item) => item.document !== distributor.document
    );
    setData(updatedData);
  };

  // const handleCancel = () => {
  //   form.resetFields();
  //   setIsOpen(false);
  //   setIsEditing(false);
  //   setCurrentItem(null);
  //   setFormDistributor(initialValues);
  // };

  const columns: TableProps<IPropsValues>["columns"] = [
    {
      title: "CNPJ",
      dataIndex: "document",
      key: "document",
      render: (document) => <Typography.Text>{document}</Typography.Text>,
    },
    {
      title: "Nome Fantasia",
      dataIndex: "tradeName",
      key: "tradeName",
      render: (tradeName) => <Typography.Text>{tradeName}</Typography.Text>,
    },
    {
      title: "Razão Social",
      dataIndex: "legalName",
      key: "legalName",
      render: (legalName) => <Typography.Text>{legalName}</Typography.Text>,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (email) => <Typography.Text>{email}</Typography.Text>,
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => <Typography.Text>{phone}</Typography.Text>,
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      render: (text, record: IPropsValues) => (
        <Typography.Text>
          {record.street}, {record.numberAddress}, {record.complement}
          {record.complement ? ", " : null}
          {record.neighborhood}, {record.city}-{record.uf}
        </Typography.Text>
      ),
    },
    {
      title: "Tipo de Unidade",
      dataIndex: "unitType",
      key: "unitType",
      render: (unitType) => <Typography.Text>{unitType}</Typography.Text>,
    },
    {
      title: "Código ANP",
      dataIndex: "anpCode",
      key: "anpCode",
      render: (anpCode) => <Typography.Text>{anpCode}</Typography.Text>,
    },
    {
      title: "Frete por Litro",
      dataIndex: "freightPerLiter",
      key: "freightPerLiter",
      render: (freightPerLiter) => (
        <Typography.Text>
          {formatCurrency(freightPerLiter.toString())}
        </Typography.Text>
      ),
    },
    {
      title: "Prazo (dias)",
      dataIndex: "deadline",
      key: "deadline",
      render: (deadline) => <Typography.Text>{deadline}</Typography.Text>,
    },
    {
      title: "Custo financeiro (mês)",
      dataIndex: "financialCost",
      key: "financialCost",
      render: (financialCost) => (
        <Typography.Text>
          {formatCurrency(financialCost.toString())}
        </Typography.Text>
      ),
    },
    {
      title: "Observações",
      dataIndex: "observations",
      key: "observations",
      align: "center",
      render: (observations) =>
        observations ? (
          <Popover content={observations}>
            <InfoCircleOutlined />
          </Popover>
        ) : null,
    },
    {
      title: "Editar | Excluir",
      key: "action",
      align: "center",
      render: (record: IPropsValues) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Distribuidoras
      </Typography.Title>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                handleSearch(searchText);
              }
            }}
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => handleSearch(searchText)}
              />
            }
          />
        </Col>
        <CustomButton type="primary" onClick={handleAdd}>
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>
      <Table columns={columns} dataSource={data} loading={loading} />
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width="90vw"
        style={{ height: "97vh", overflowY: "auto" }}
        centered
      >
        <DistributorRegistrationForm distributor={formDistributor} />
      </Modal>
    </Flex>
  );
};

export default DistributorsFuel;
