export type MenuItem = {
  label: string;
  key: string;
  route?: string;
  children?: MenuItem[];
  onClick?: () => void;
};

export const items: MenuItem[] = [
  { label: "Dashboard", key: "dashboard", route: "/" },
  {
    label: "Cadastros",
    key: "registrations",
    children: [
      { label: "Clientes", key: "Clients", route: "/Clients" },
      { label: "Pessoas", key: "People", route: "/People" },

      {
        label: "Distribuidoras",
        key: "DistributorsFuel",
        route: "/DistributorsFuel",
      },
      {
        label: "Base de Operações",
        key: "OperationBase",
        route: "/OperationBase",
      },
      { label: "Consultores", key: "Consultants", route: "/Consultants" },
    ],
  },
  {
    label: "Cotações",
    key: "quotes",
    children: [
      { label: "Nova", key: "quotesnew", route: "/QuotesNew" },
      { label: "Acompanhar", key: "quoteanalysis", route: "/QuoteAnalysis" },
      { label: "Análise", key: "quotestrack", route: "/QuotesTrack" },
    ],
  },

  {
    label: "Configurações",
    key: "Configurations",
    children: [
      {
        label: "Categorias de Clientes",
        key: "CategoryCustomers",
        route: "/categoryCustomers",
      },
      { label: "Bandeiras ANP", key: "flaganp", route: "/flagANP" },
      {
        label: "Planos e Preços",
        key: "PlanPriceSubscription",
        route: "/planPriceSubscription",
      },
      { label: "Product ANP", key: "productanp", route: "/productANP" },
      { label: "Regiões ANP", key: "regionsanp", route: "/regionsANP" },
    ],
  },

  {
    label: "Blog",
    key: "blog",
    onClick: () =>
      window.open(
        "https://site.salespetro.com.br",
        "_blank",
        "noopener,noreferrer"
      ),
  },

  { label: "Minha conta", key: "account", route: "/account" },
];
