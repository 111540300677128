import styled from "styled-components";
import { Col } from "antd";

export const ColCustom = styled(Col)<{ isMobile: boolean; isRigth: boolean }>`
  width: 100%;
  background-color: #fff;
  position: relative;
  padding: ${({ isMobile }) => (isMobile ? "0" : "24px")};
  margin: ${({ isMobile }) => (isMobile ? "0" : "0 0 24px")};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;
