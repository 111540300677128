import { useState, useEffect, useContext, createContext } from "react";
import { getToken, removeToken, setToken } from "../services/utils/users";
import api from "../services/axios";
import { IApiPayload } from "../pages/publics/Register/utils";

interface AuthContextProps {
  authenticated: boolean;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (userData: IApiPayload) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  authenticated: false,
  loading: true,
  signIn: async () => Promise.resolve(),
  signUp: async () => Promise.resolve(),
  signOut: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Começa carregando

  // Verificar token ao montar
  useEffect(() => {
    const token = getToken();
    if (token) {
      setAuthenticated(true);
    }
    setLoading(false); // Finaliza carregamento
  }, []);

  const signIn = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await api.post("/auth/login", {
        email,
        password,
      });

      setToken(data.accessToken);
      setAuthenticated(true);
    } finally {
      setLoading(false);
    }
  };

  const signOut = () => {
    removeToken();
    setAuthenticated(false);
  };

  const signUp = async (userData: IApiPayload): Promise<void> => {
    setLoading(true);
    try {
      await api.post("/customers/register", userData);
    } catch (err: any) {
      if (err.response) {
        console.error("Erro:", err.response.data);
        throw new Error(
          err.response.data.message || "Erro ao fazer o cadastro. Tente novamente."
        );
      }
      throw new Error("Erro desconhecido. Verifique sua conexão e tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ authenticated, loading, signIn, signOut, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);