/*import { DataType } from "./types";*/
import { DataType } from "./types";

export const data: DataType[] = [
  {
    id: "1",
    name: "Ribeirão Preto",
    regions: "Ribeirão Preto",
  },
  {
    id: "2",
    name: "Paulinea",
    regions: "Paulinea",
  },
];