/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Col,
  Descriptions,
  Form,
  Flex,
  Input,
  Image,
  message,
  Radio,
  Row,
  Select,
  Button,
  Space,
  Table,
  Typography,
} from "antd";
import * as Yup from "yup";

import { ColCustom } from "./styles";
import ImageLogo from "../../assets/images/logo.svg";
import CustomInput from "../Input/index";
import CustomButton from "../Buttom/index";
import CustomSelect from "../Select/index";
import { initialValues, IPropsValues, RegisterSchema } from "./utils";
import { LinkOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { peopleData } from "../../pages/privates/PeopleRegistration/peopleMock";

const DistributorRegistrationForm = ({
  distributor,
}: {
  distributor: IPropsValues;
}) => {
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const [linkedRegions, setLinkedRegions] = useState([]);
  const [linkedBases, setLinkedBases] = useState([]);
  const [showRegionSelect, setShowRegionSelect] = useState(false);
  const [showBaseSelect, setShowBaseSelect] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [values, setValues] = useState<IPropsValues>(
    distributor || initialValues
  );
  const [filteredPeople, setFilteredPeople] = useState(peopleData);
  const [selectedPerson, setSelectedPerson] = useState<IPropsValues | null>(
    null
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (distributor) {
      setValues(distributor || initialValues);
    }
  }, [distributor]);

  const handleSearch = (value: string) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = peopleData.filter((person) => {
      return Object.values(person).some((fieldValue) => {
        if (typeof fieldValue === "string") {
          return fieldValue.toLowerCase().includes(lowerCaseValue);
        }
        return false;
      });
    });
    setFilteredPeople(filtered);
  };

  // const handleChange = (key: string, value: any) => {
  //   setValues((prev) => ({ ...prev, [key]: value }));
  // };

  const handleSubmit = async () => {
    try {
      await RegisterSchema.validate(values, { abortEarly: false });

      messageApi.success("Pessoa cadastrada com sucesso!");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            validationErrors[err.path] = err.message;
          }
        });
        setErrors(validationErrors);
      } else {
        messageApi.error("Falha ao criar pessoa. Tente novamente mais tarde.");
      }
    }
  };

  const handleLinkRegion = () => {
    setShowRegionSelect(!showRegionSelect);
  };

  const handleLinkBase = () => {
    setShowBaseSelect(!showBaseSelect);
  };

  const handleAddRegion = () => {
    console.log("Add new region");
  };

  const handleAddBase = () => {
    console.log("Add new base");
  };

  return (
    <Flex
      style={{
        padding: isMobile ? 0 : 8,
        flexDirection: isMobile ? "column" : "row",
        boxShadow: "none",
      }}
    >
      {contextHolder}

      <ColCustom
        isMobile={isMobile}
        isRigth={false}
        style={{
          boxShadow: "none",
        }}
      >
        <Row align="middle" style={{ marginBottom: 20, width: "100%" }}>
          <Image style={{ width: "300px" }} src={ImageLogo} preview={false} />
        </Row>
        <Typography.Title level={3} style={{ margin: "8px 0 16px" }}>
          Cadastro de Distribuidoras
        </Typography.Title>

        <Row
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            marginBottom: 16,
            justifyContent: "left",
          }}
        >
          <Col
            style={{
              width: isMobile ? "100%" : "10%",
              marginBottom: isMobile ? 8 : 0,
            }}
          >
            <Typography.Text style={{ verticalAlign: "bottom" }}>
              Instituição
            </Typography.Text>
          </Col>
          <Col style={{ width: isMobile ? "100%" : "75%", marginRight: 10 }}>
            <CustomSelect
              placeholder="Digite o Nome ou CNPJ"
              showSearch
              allowClear
              onSearch={handleSearch}
              onChange={(value) => {
                if (!value) {
                  setSelectedPerson(null);
                  setValues((prevValues) => ({
                    ...prevValues,
                    institution: null,
                  }));
                  return;
                }
                const selected = filteredPeople.find(
                  (person) => person.document === value
                );
                setSelectedPerson(selected || null);
                setValues((prevValues) => ({
                  ...prevValues,
                  institution: value,
                }));
              }}
            >
              {filteredPeople.map((person: IPropsValues) => (
                <Select.Option key={person.document} value={person.document}>
                  {person.document} - {person.tradeName}
                  {!isMobile && `- ${person.street} - ${person.city}`}
                </Select.Option>
              ))}
            </CustomSelect>
          </Col>
          <Col
            style={{
              width: isMobile ? "25%" : "10%",
              marginTop: isMobile ? "10px" : "0px",
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                window.open(window.location.origin + "#/People", "_blank")
              }
            >
              Novo
              <PlusOutlined />
            </Button>
          </Col>
        </Row>

        <Typography.Title level={5}>Dados da empresa</Typography.Title>
        {selectedPerson ? (
          <Typography.Title level={5}>
            {selectedPerson.document} - {selectedPerson.tradeName} -
            {selectedPerson.legalName}
          </Typography.Title>
        ) : null}
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          {selectedPerson ? (
            <Descriptions column={isMobile ? 1 : 2} bordered={false}>
              <Descriptions.Item label="E-mail">
                {selectedPerson.email}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone">
                {selectedPerson.phone}
              </Descriptions.Item>
              <Descriptions.Item label="CEP">
                {selectedPerson.postalCode}
              </Descriptions.Item>
              <Descriptions.Item label="UF">
                {selectedPerson.uf}
              </Descriptions.Item>
              <Descriptions.Item label="Cidade">
                {selectedPerson.city}
              </Descriptions.Item>
              <Descriptions.Item label="Endereço">
                {selectedPerson.street}
              </Descriptions.Item>
              <Descriptions.Item label="Número">
                {selectedPerson.numberAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Bairro">
                {selectedPerson.neighborhood}
              </Descriptions.Item>
              <Descriptions.Item label="Complemento">
                {selectedPerson.complement}
              </Descriptions.Item>
            </Descriptions>
          ) : null}
        </div>

        <Typography.Title level={5} style={{ marginBottom: "24px" }}>
          Informações comerciais
        </Typography.Title>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Tipo de Unidade" name="unitType">
              <Radio.Group>
                <Radio value="matriz">Matriz</Radio>
                <Radio value="filial">Filial</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <CustomInput title="Código ANP" placeholder="Digite o Código ANP" />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <CustomInput title="Frete por Litro" placeholder="Digite o Frete" />
          </Col>
          <Col span={8}>
            <CustomInput title="Prazo (dias)" placeholder="Digite o Prazo" />
          </Col>
          <Col span={8}>
            <CustomInput
              title="Custo financeiro (mês)"
              placeholder="Digite o Custo"
            />
          </Col>
        </Row>
        <Typography.Text>Observações</Typography.Text>
        <Input.TextArea placeholder="Digite as observações" />
      </ColCustom>

      <ColCustom
        isMobile={isMobile}
        isRigth
        style={{
          boxShadow: "none",
          marginTop: !isMobile ? "125px" : "0px",
        }}
      >
        <div
          style={{
            border: "1px solid #F5F5F5",
            padding: "16px",
            borderRadius: "8px",
            marginBottom: "16px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Space
            align="baseline"
            style={{
              marginBottom: 8,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={5}>Regiões de Atuação</Typography.Title>
            <Button
              type="primary"
              onClick={() =>
                window.open(window.location.origin + "#/regionsANP", "_blank")
              }
            >
              Novo
              <PlusOutlined />
            </Button>
          </Space>

          <Table
            dataSource={linkedRegions}
            columns={[{ title: "Região", dataIndex: "region", key: "region" }]}
            style={{ marginBottom: 8 }}
          />

          {!showRegionSelect && (
            <Button onClick={handleLinkRegion}>
              Vincular Região
              <LinkOutlined />
            </Button>
          )}
          {showRegionSelect && (
            <Space.Compact style={{ width: "100%" }}>
              <Button onClick={handleLinkRegion} icon={<LeftOutlined />} />
              <Select
                placeholder="Selecione uma região"
                style={{ width: "100%" }}
              />
            </Space.Compact>
          )}
        </div>
        <div
          style={{
            border: "1px solid #F5F5F5",
            padding: "16px",
            borderRadius: "8px",
            marginBottom: "16px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Space
            align="baseline"
            style={{
              marginBottom: 8,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={5}>Bases de Operação</Typography.Title>
            <Button type="primary" onClick={handleAddBase}>
              Novo
              <PlusOutlined />
            </Button>
          </Space>

          <Table
            dataSource={linkedBases}
            columns={[{ title: "Base", dataIndex: "base", key: "base" }]}
            style={{ marginBottom: 8 }}
          />

          {!showBaseSelect && (
            <Button onClick={handleLinkBase}>
              Vincular Base
              <LinkOutlined />
            </Button>
          )}

          {showBaseSelect && (
            <Space.Compact style={{ width: "100%" }}>
              <Button onClick={handleLinkBase} icon={<LeftOutlined />} />
              <Select
                placeholder="Selecione uma base"
                style={{ width: "100%" }}
              />
            </Space.Compact>
          )}
        </div>
        <Row style={{ marginTop: 20, justifyContent: "center" }}>
          <Col>
            <CustomButton type="primary" size="large" onClick={handleSubmit}>
              Registrar
            </CustomButton>
          </Col>
        </Row>
      </ColCustom>
    </Flex>
  );
};

export default DistributorRegistrationForm;
