/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Col, Flex, Image, message, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { ColCustom } from "./styles";
import ImageLogo from "../../assets/images/logo.svg";
import CustomInput from "../Input/index";
import CustomButton from "../Buttom/index";

import {
  formatCpfCnpj,
  formatPhone,
  formatPostalCode,
  formatTextOnlyUpperCase,
} from "../../utils/masks";
import { initialValues, IPropsValues, RegisterSchema } from "./utils";

interface PeopleRegistrationFormProps {
  person: IPropsValues;
}

const PeopleRegistrationForm = ({ person }: PeopleRegistrationFormProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const isMobile = useMediaQuery({ maxWidth: 900 });
  // const { loading, signUp } = useAuth();
  // const [lockPassword, setLockPassword] = useState<boolean>(true);
  const [values, setValues] = useState<IPropsValues>(initialValues);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (person) {
      setValues(person);
    }
  }, [person]);

  const handleChange = (key: string, value: string) => {
    setValues((prev: IPropsValues) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async () => {
    try {
      await RegisterSchema.validate(values, { abortEarly: false });

      messageApi.success("Pessoa cadastrada com sucesso!");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            validationErrors[err.path] = err.message;
          }
        });
        setErrors(validationErrors);
      } else {
        messageApi.error("Falha ao criar pessoa. Tente novamente mais tarde.");
      }
    }
  };

  return (
    <Flex
      style={{
        padding: isMobile ? 0 : 6,
        flexDirection: isMobile ? "column" : "row",
        boxShadow: "none",
      }}
    >
      {contextHolder}
      <ColCustom
        isMobile={isMobile}
        isRigth={false}
        style={{
          boxShadow: "none",
        }}
      >
        <Row align="middle" style={{ marginBottom: "20px" }}>
          <Image style={{ width: "200px" }} src={ImageLogo} preview={false} />
        </Row>
        <Typography.Title level={3} style={{ margin: "8px 0 8px" }}>
          Cadastro de Pessoa
        </Typography.Title>
        <CustomInput
          title="CNPJ / CPF"
          placeholder="Informe seu CNPJ ou CPF"
          value={formatCpfCnpj(values.document)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("document", e.target.value)
          }
        />
        <CustomInput
          title="Nome Fantasia / Nome Identificador"
          placeholder="Informe o nome fantasia ou nome identificador"
          value={values.tradeName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("tradeName", e.target.value)
          }
        />
        <CustomInput
          title="Razão Social / Nome"
          placeholder="Informe sua Razão Social ou o seu Nome"
          value={values.legalName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("legalName", e.target.value)
          }
        />

        <Typography.Title level={3} style={{ margin: "10px 0 5px" }}>
          Contato
        </Typography.Title>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: !isMobile ? "65%" : "100%" }}>
            <CustomInput
              title="E-Mail"
              placeholder="Informe seu e-mail"
              value={values.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("email", e.target.value)
              }
            />
          </Col>
          <Col style={{ width: !isMobile ? "35%" : "100%" }}>
            <CustomInput
              title="Telefone"
              placeholder="Telefone"
              value={formatPhone(values.phone)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("phone", e.target.value)
              }
            />
          </Col>
        </Row>

        <Typography.Title level={3} style={{ margin: "10px 0 5px" }}>
          Endereço
        </Typography.Title>
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: !isMobile ? "20%" : "100%" }}>
            <CustomInput
              title="CEP"
              placeholder="CEP"
              value={formatPostalCode(values.postalCode)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("postalCode", e.target.value)
              }
            />
          </Col>
          <Col style={{ width: !isMobile ? "40%" : "100%" }}>
            <CustomInput
              title="UF"
              placeholder="UF"
              value={formatTextOnlyUpperCase(values.uf)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("uf", e.target.value)
              }
            />
          </Col>
          <Col style={{ width: !isMobile ? "40%" : "100%" }}>
            <CustomInput
              title="Cidade"
              placeholder="Cidade"
              value={values.city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("city", e.target.value)
              }
            />
          </Col>
        </Row>
        <CustomInput
          title="Endereço"
          placeholder="Endereço"
          value={values.street}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("street", e.target.value)
          }
        />
        <Row
          style={{
            gap: isMobile ? 0 : "10px",
            flexFlow: "nowrap",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Col style={{ width: !isMobile ? "20%" : "100%" }}>
            <CustomInput
              title="Número"
              placeholder="Número"
              value={values.numberAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("numberAddress", e.target.value)
              }
            />
          </Col>
          <Col style={{ width: !isMobile ? "80%" : "100%" }}>
            <CustomInput
              title="Bairro"
              placeholder="Informe o Bairro"
              value={values.neighborhood}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("neighborhood", e.target.value)
              }
            />
          </Col>
        </Row>
        <CustomInput
          title="Complemento"
          placeholder="Informe um complemento"
          value={values.complement}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange("complement", e.target.value)
          }
        />

        <Row style={{ marginTop: 20, justifyContent: "center" }}>
          <Col>
            <CustomButton type="primary" size="large" onClick={handleSubmit}>
              Registrar
            </CustomButton>
          </Col>
        </Row>
      </ColCustom>
    </Flex>
  );
};

export default PeopleRegistrationForm;
