import { IPropsValues } from "../../../components/DistributorRegistrationForm/utils";

export const distributorsMock: IPropsValues[] = [
  {
    document: "123.456.789-00",
    tradeName: "Loja da Maria teste",
    legalName: "Maria da Silva",
    email: "maria@email.com",
    phone: "(11) 1234-5678",
    postalCode: "01001-000",
    uf: "SP",
    city: "São Paulo",
    street: "Rua A",
    neighborhood: "Centro",
    numberAddress: "123",
    complement: "Apt 4",
    unitType: "matriz", // Added
    anpCode: "12345", // Added
    freightPerLiter: 0.5, // Added
    deadline: 15, // Added
    financialCost: 1000.0, // Added - represents 2%
    observations: "Entrega somente pela manhã", // Added
  },
  {
    document: "12.345.678/0001-99",
    tradeName: "Padaria do Zé",
    legalName: "José Padaria LTDA teste",
    email: "ze@email.com",
    phone: "(21) 9876-5432",
    postalCode: "20001-001",
    uf: "RJ",
    city: "Rio de Janeiro",
    street: "Rua B",
    neighborhood: "Copacabana",
    numberAddress: "456",
    complement: "",
    unitType: "filial",
    anpCode: "67890",
    freightPerLiter: 0.7,
    deadline: 10,
    financialCost: 1000.0, // Represents 1.5%
    observations: "Pedidos mínimos de R$ 100,00",
  },
  {
    document: "987.654.321-01",
    tradeName: "Mercadinho do João",
    legalName: "João Mercado MEI",
    email: "joao@email.com.teste",
    phone: "(31) 4567-8901",
    postalCode: "30001-002",
    uf: "MG",
    city: "Belo Horizonte",
    street: "Rua C",
    neighborhood: "Savassi",
    numberAddress: "789",
    complement: "Sala 2",
    unitType: "matriz",
    anpCode: "13579",
    freightPerLiter: 0.6,
    deadline: 20,
    financialCost: 1000.0, // Represents 2.5%
    observations: "Não aceita cartão de crédito",
  },
  {
    document: "44.777.145/0001-51",
    tradeName: "Tech Solutions",
    legalName: "Tech Solutions S/A",
    email: "techsolutions@email.com",
    phone: "(11) 2584-7410",
    postalCode: "14001-752",
    uf: "SP",
    city: "São Paulo",
    street: "Av. Paulista",
    neighborhood: "Bela Vista",
    numberAddress: "2584",
    complement: "Andar 12",
    unitType: "filial",
    anpCode: "24680",
    freightPerLiter: 0.4,
    deadline: 5,
    financialCost: 1000.0, // Represents 1%
    observations: "Pagamento antecipado",
  },
  {
    document: "11.222.333/0001-11",
    tradeName: "Green Energy",
    legalName: "Green Energy LTDA",
    email: "greenenergy@email.com",
    phone: "(41) 9654-7885",
    postalCode: "80001-254",
    uf: "PR",
    city: "Curitiba",
    street: "Rua XV de Novembro",
    neighborhood: "Centro",
    numberAddress: "147",
    complement: "",
    unitType: "matriz",
    anpCode: "97531",
    freightPerLiter: 0.8,
    deadline: 25,
    financialCost: 1000.0, // Represents 3%
    observations: "", // Empty observation is allowed
  },
];
