import { IPropsValues } from "../../../components/PeopleRegistrationForm/utils";

export const peopleData: IPropsValues[] = [
  {
    document: "123.456.789-00", // CPF
    tradeName: "Loja da Maria teste",
    legalName: "Maria da Silva",
    email: "maria@email.com",
    phone: "(11) 1234-5678",
    postalCode: "01001-000",
    uf: "SP",
    city: "São Paulo",
    street: "Rua A",
    neighborhood: "Centro",
    numberAddress: "123",
    complement: "Apt 4",
  },
  {
    document: "12.345.678/0001-99", // CNPJ
    tradeName: "Padaria do Zé",
    legalName: "José Padaria LTDA teste",
    email: "ze@email.com",
    phone: "(21) 9876-5432",
    postalCode: "20001-001",
    uf: "RJ",
    city: "Rio de Janeiro",
    street: "Rua B",
    neighborhood: "Copacabana",
    numberAddress: "456",
    complement: "",
  },
  {
    document: "987.654.321-01", // CPF
    tradeName: "Mercadinho do João",
    legalName: "João Mercado MEI",
    email: "joao@email.com.teste",
    phone: "(31) 4567-8901",
    postalCode: "30001-002",
    uf: "MG",
    city: "Belo Horizonte",
    street: "Rua C",
    neighborhood: "Savassi",
    numberAddress: "789",
    complement: "Sala 2",
  },
  {
    document: "44.777.145/0001-51", // CNPJ
    tradeName: "Tech Solutions",
    legalName: "Tech Solutions S/A",
    email: "techsolutions@email.com",
    phone: "(11) 2584-7410",
    postalCode: "14001-752",
    uf: "SP",
    city: "São Paulo",
    street: "Av. Paulista",
    neighborhood: "Bela Vista",
    numberAddress: "2584",
    complement: "Andar 12",
  },
  {
    document: "11.222.333/0001-11", // CNPJ
    tradeName: "Green Energy",
    legalName: "Green Energy LTDA",
    email: "greenenergy@email.com",
    phone: "(41) 9654-7885",
    postalCode: "80001-254",
    uf: "PR",
    city: "Curitiba",
    street: "Rua XV de Novembro",
    neighborhood: "Centro",
    numberAddress: "147",
    complement: "",
  },
];
