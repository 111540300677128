import api from "../axios";
import { IPropsResponseBrands, IPropsResponseCategory } from "./types";

export const getCategory = async (): Promise<IPropsResponseCategory[]> => {
  const response = await api.get('/customers/category')

  return response.data
};

export const createCategory = async (name: string, anpCode: string) => {
  await api.post('/customers/category', {
    name, anpCode
  })
};

export const getBrands = async (): Promise<IPropsResponseBrands[]> => {
  const response = await api.get('/brands')

  return response.data
};

export const createBrand = async (name: string, type: string) => {
  await api.post('/brands', {
    name, type
  })
};