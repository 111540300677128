import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { getToken } from '../services/utils/users';

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const { authenticated, loading } = useAuth();
  const token = getToken();

  if (loading) {
    return <></>;
  }

  return authenticated && token ? element : <Navigate to="/login" />;
};

export default PrivateRoute;