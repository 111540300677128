import * as Yup from "yup";

export const initialValues = {
  document: "",
  tradeName: "",
  legalName: "",
  email: "",
  phone: "",
  postalCode: "",
  uf: "",
  city: "",
  street: "",
  neighborhood: "",
  numberAddress: "",
  complement: "",
  type: "residential",
  category_id: undefined,
  brand: undefined,
  anp_code: "",
  operation_base: undefined,
  sales_volume: "",
  average_revenue: "",
  convenience_revenue: "",
  oil_change_revenue: "",
  fuel_pump_revenue: "",
  subscription_plan: "Plano 1",
};

export interface IPropsValues {
  document: string;
  tradeName: string;
  legalName: string;
  email: string;
  phone: string;
  postalCode: string;
  uf: string;
  city: string;
  street: string;
  neighborhood: string;
  numberAddress: string;
  complement: string;
}

export const RegisterSchema = Yup.object().shape({
  document: Yup.string().required("CNPJ/CPF é obrigatório"),
  tradeName: Yup.string().required("Nome de Identificação é obrigatório"),
  legalName: Yup.string().required("Razão Social/Nome é obrigatório"),
  email: Yup.string().email("Email inválido").required("Email é obrigatório"),
  phone: Yup.string().required("Telefone é obrigatório"),
  postalCode: Yup.string().required("CEP é obrigatório"),
  uf: Yup.string()
    .length(2, "UF deve ter 2 caracteres")
    .required("UF é obrigatória"),
  city: Yup.string().required("Cidade é obrigatória"),
  street: Yup.string().required("Endereço é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  numberAddress: Yup.string().required("Número do endereço é obrigatório"),
  complement: Yup.string().nullable(),
});
