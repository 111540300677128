export const formatPhone = (value: string): string => {
  const numericValue = value?.replace(/\D/g, "");

  // Aplica o formato (00) 00000-0000
  return numericValue
    .replace(/^(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .slice(0, 15);
};

export const formatCpfCnpj = (value: string): string => {
  const numericValue = value?.replace(/\D/g, "");

  if (numericValue.length <= 11) {
    // Formatar como CPF: 000.000.000-00
    return numericValue
      .replace(/^(\d{3})(\d)/, "$1.$2")
      .replace(/^(\d{3}\.\d{3})(\d)/, "$1.$2")
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2");
  } else {
    // Formatar como CNPJ: 00.000.000/0000-00
    return numericValue
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2}\.\d{3})(\d)/, "$1.$2")
      .replace(/^(\d{2}\.\d{3}\.\d{3})(\d)/, "$1/$2")
      .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d)/, "$1-$2");
  }
};

export const removeCaractersFormatting = (value: string): string => {
  return value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
};

export const formatPostalCode = (value: string): string => {
  const numericValue = value?.replace(/\D/g, "");

  // Aplica o formato 00000-000
  return numericValue.replace(/^(\d{5})(\d)/, "$1-$2").slice(0, 9);
};

export const formatTextOnlyUpperCase = (value: string): string => {
  // Remove tudo que não for letras (maiúsculas, minúsculas ou espaços)
  return value?.replace(/[^a-zA-ZÀ-ÿ\s]/g, "").toUpperCase();
};

export const formatCurrency = (value: string): string => {
  // Remove tudo que não seja número
  const numericValue = value?.replace(/\D/g, "");

  if (!numericValue) return "";

  // Remove zeros à esquerda
  const cleanedValue = numericValue.replace(/^0+/, "");

  // Cria a versão inteira e a parte dos centavos
  let integerPart = cleanedValue.slice(0, -2) || "0"; // Parte inteira
  let decimalPart = cleanedValue.slice(-2); // Parte dos centavos

  // Formata a parte inteira com separadores de milhar
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return `R$ ${integerPart},${decimalPart}`;
};

export const parseCurrency = (value: string): number => {
  if (!value) return 0;

  // Remove o prefixo "R$ " e espaços em branco
  const cleanedValue = value.replace("R$", "").trim();

  // Remove os pontos e troca a vírgula por ponto
  const normalizedValue = cleanedValue.replace(/\./g, "").replace(",", ".");

  // Converte para número ou retorna 0 caso não seja válido
  return parseFloat(normalizedValue) || 0;
};

export const formatPercentage = (
  value: number | string | undefined
): string => {
  if (value === undefined || value === null || isNaN(Number(value))) {
    return "0,00%"; // or return an empty string, whatever suits your needs
  }

  const numericValue = Number(value);
  const formattedValue = (numericValue * 100).toFixed(2).replace(".", ",");
  return `${formattedValue}%`;
};
