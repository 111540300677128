/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Form,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import CustomButton from "../../../components/Buttom";
import CustomInput from "../../../components/Input";
import { colors } from "../../../theme/colors";
import PeopleRegistrationForm from "../../../components/PeopleRegistrationForm";
import {
  initialValues,
  IPropsValues,
} from "../../../components/PeopleRegistrationForm/utils";

import { peopleData } from "./peopleMock";

const People = () => {
  const [data, setData] = useState<IPropsValues[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [formPerson, setFormPerson] = useState<IPropsValues>(initialValues);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<IPropsValues | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setData(peopleData);
  }, []);

  const handleSearch = (value: string) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = peopleData.filter((person) => {
      return Object.values(person).some((fieldValue) => {
        if (typeof fieldValue === "string") {
          return fieldValue.toLowerCase().includes(lowerCaseValue);
        }
        return false;
      });
    });
    setData(filtered);
  };

  const handleAdd = () => {
    setFormPerson(initialValues);
    setIsOpen(true);
  };

  const handleEdit = (person: IPropsValues) => {
    setFormPerson(person);
    setIsOpen(true);
  };

  const handleDelete = (person: IPropsValues) => {
    // Implement delete functionality
  };

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
    setIsEditing(false);
    setCurrentItem(null);
    setFormPerson(initialValues); // crucial to clear form data on cancel as well.
  };

  const columns: TableProps<IPropsValues>["columns"] = [
    {
      title: "CNPJ/CPF",
      dataIndex: "document",
      key: "document",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Nome Fantasia",
      dataIndex: "tradeName",
      key: "tradeName",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Razão Social",
      dataIndex: "legalName",
      key: "legalName",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: "Editar | Excluir",
      key: "action",
      align: "center",
      render: (record: IPropsValues) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: colors.info, cursor: "pointer", fontSize: 18 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este item?"
            onConfirm={() => handleDelete(record)}
            okText="Sim"
            cancelText="Não"
            placement="topRight"
          >
            <DeleteOutlined
              style={{ color: colors.error, cursor: "pointer", fontSize: 18 }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Flex vertical>
      <Typography.Title level={5} style={{ padding: 0 }}>
        Cadastro de Pessoas
      </Typography.Title>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", flexWrap: "nowrap", marginBottom: 20 }}
      >
        <Col style={{ width: "50%" }}>
          <CustomInput
            style={{ width: "50%" }}
            title="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                handleSearch(searchText);
              }
            }}
            addonAfter={
              <SearchOutlined
                style={{ cursor: "pointer", color: colors.info }}
                onClick={() => handleSearch(searchText)}
              />
            }
          />
        </Col>
        <CustomButton type="primary" onClick={handleAdd}>
          <PlusOutlined /> Criar novo
        </CustomButton>
      </Row>
      <Table columns={columns} dataSource={data} loading={loading} />
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width="90vw"
        style={{ height: "97vh", overflowY: "auto" }}
        centered
      >
        <PeopleRegistrationForm person={formPerson} />
      </Modal>
    </Flex>
  );
};

export default People;
