import { QuoteTypes } from "./quoteTypes";

export const quotesData: QuoteTypes[] = [
  {
    id: "PetroLog1",
    distributor: "PetroLog",
    operationBase: "Ribeirão Preto",
    issueDate: new Date(2024, 10, 28),
    operationDate: new Date(2024, 11, 5),
    operationValue: "120000.00",
    quantity: 15000,
  },
  {
    id: "Shell1",
    distributor: "Shell",
    operationBase: "São Paulo",
    issueDate: new Date(2024, 9, 15),
    operationDate: new Date(2024, 10, 20),
    operationValue: "85000.50",
    quantity: 10000,
  },
  {
    id: "Ipiranga1",
    distributor: "Ipiranga",
    operationBase: "Campinas",
    issueDate: new Date(2024, 11, 2),
    operationDate: new Date(2024, 11, 10),
    operationValue: "200000.00",
    quantity: 25000,
  },
  {
    id: "BR1",
    distributor: "BR",
    operationBase: "Rio de Janeiro",
    issueDate: new Date(2024, 8, 20),
    operationDate: new Date(2024, 9, 28),
    operationValue: "150000.75",
    quantity: 18000,
  },
  {
    id: "PetroLog2",
    distributor: "PetroLog",
    operationBase: "Santos",
    issueDate: new Date(2024, 10, 10),
    operationDate: new Date(2024, 10, 18),
    operationValue: "90000.25",
    quantity: 12000,
  },
];
