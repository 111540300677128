import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { getToken } from '../services/utils/users';

const PublicRoute = ({ element }: { element: JSX.Element }) => {
  const { authenticated, loading } = useAuth();
  const token = getToken();

  if (loading) {
    return <></>;
  }

  return authenticated && token ? <Navigate to="/" /> : element;
};

export default PublicRoute;